import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { Chip } from '../../atoms/Chip';
import { NavigationImg } from '../../atoms/Card/navigationCard';

const KbLeftSideBar = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.contrastText,
  paddingBlock: '2.5rem',
  borderRadius: '0.375rem',
  flex: '0 0 33.3%',
  height: '100%',
  flexDirection: 'column',
  overflow: 'hidden',
}));

const KbLeftSideBarContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100% - 5rem)',
  overflow: 'auto',
  boxSizing: 'border-box',
  paddingInline: '2.5rem',
}));

interface LeftSideBarInterface {
  children?: React.ReactNode;
}
interface LeftSideBarHeaderInterface {
  title: string;
  technologyAdoption: string;
  date?: string;
  src: string;
  alt: string;
}

export const LeftSideBar = ({ children }: LeftSideBarInterface) => {
  return <KbLeftSideBar>{children}</KbLeftSideBar>;
};

const KbLeftSideBarHeader = styled(Box)(({ theme }) => ({
  // paddingInline: '2.5rem',
  marginBottom: '1rem',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  textTransform: 'capitalize',
  '& .MuiTypography-h3': {
    display: 'flex',
    marginLeft: '0.5rem',
  },
  '& .technologyAdoption': {
    textTransform: 'capitalize',
    marginLeft: '0.75rem',
    lineHeight: 1.5,
  },
  '& .lastEdit': {
    color: theme.palette.grey[100],
    textTransform: 'initial',
    marginLeft: '0.5rem',
  },
}));

export const LeftSideBarHeader = ({ title, technologyAdoption, date, src, alt }: LeftSideBarHeaderInterface) => {
  return (
    <KbLeftSideBarHeader>
      <NavigationImg src={src} alt={alt} name={title} />
      <Box>
        <Typography variant='h3'>
          {title} <Chip impact={technologyAdoption} className='technologyAdoption' />
        </Typography>
      </Box>
    </KbLeftSideBarHeader>
  );
};

export const LeftSideBarContent = ({ children }: LeftSideBarInterface) => {
  return <KbLeftSideBarContent>{children}</KbLeftSideBarContent>;
};
