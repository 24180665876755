import { Box, Divider, List, Typography } from '@mui/material';
import { Container } from '@mui/system';
import { styled } from '@mui/material/styles';
import { BlogListItem } from '../../Components/molecules/ListItem';
import BlogImg from '../../assets/images/blogImg.jpg';
import { MarkdownComponent } from '../../Components/atoms/Markdown';
import { formatDate } from '../TechDigests/banner';

interface recentUpdateProps {
  date: string;
  description: string;
  author: string;
  imgPath?: string;
  alt?: string;
  title?: string;
}

export interface cardProps {
  recentUpdateData: any[];
}

const KbRecentlyUploaded = styled(Box)({
  paddingBlock: '3.75rem',
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100vh - 6.125rem)',
  overflow: 'auto',
});

const recentlyUploadedData = [
  {
    title: 'Handling and lowering the Boot Up Process: Spot Termination Issue in ACE RTB Servers',
    articleType: 'Hard Problem',
    technology: 'Apache Kafka',
    author: 'Rahul Kulkarni',
    impact: 'High Impact',
    date: '26 Nov 22',
    image: BlogImg,
    alt: 'BlogImg',
  },
  {
    title: 'Handling and lowering the Boot Up Process: Spot Termination Issue in ACE RTB Servers',
    articleType: 'BLOGS & TL ARTICLES',
    technology: 'Apache Kafka',
    author: 'Rahul Kulkarni',
    impact: 'Medium Impact',
    date: '26 Nov 22',
    image: BlogImg,
    alt: 'BlogImg',
  },
  {
    title: 'Handling and lowering the Boot Up Process: Spot Termination Issue in ACE RTB Servers',
    articleType: 'Hard Problem',
    technology: 'Apache Kafka',
    author: 'Rahul Kulkarni',
    impact: 'Low Impact',
    date: '26 Nov 22',
    image: BlogImg,
    alt: 'BlogImg',
  },
];

const CardsContainer = styled('div')({
  position: 'relative',
  margin: '1rem 0',
  padding: '0 1rem',
});

const CustomCard = styled(Box)({
  position: 'relative',
  zIndex: 1,
  background: '#FFF',

  backgroundColor: '#ffffff',
  overflow: 'hidden',
  marginBottom: '1.2rem',
  '& .updated-date': {
    color: '#0B93DF',
    marginBottom: '0.5rem',
  },
  '& .updated-description': {
    backgroundColor: '#fff',
    fontSize: '0.75rem',
    marginBottom: '0.1rem',
    lineHeight: '1.375rem',
    color: '#545454',
    '& ul': {
      paddingInlineStart: '1rem',
    },
    '& img': {
      width: '100%',
      height: 'auto',
      display: 'block',
    },
  },
  img: {
    width: '100%',
    height: '10rem',
  },
  '& .author': {
    textAlign: 'right',
    textTransform: 'capitalize',
  },
});

const RecentlyUploaded = () => {
  return (
    <KbRecentlyUploaded>
      <Container>
        <List>
          {recentlyUploadedData.map((data, index) => {
            return (
              <BlogListItem
                key={index}
                title={data.title}
                articleType={data.articleType}
                technology={data.technology}
                author={data.author}
                impact={data.impact}
                date={data.date}
                image={data.image}
                alt={data.alt}
              />
            );
          })}
        </List>
      </Container>
    </KbRecentlyUploaded>
  );
};

export default RecentlyUploaded;

export const RecentlyUpdatedCard = ({ recentUpdateData }: cardProps) => {
  return (
    <>
      {recentUpdateData?.length ? (
        <CardsContainer>
          {recentUpdateData?.map((data: any, index) => {
            const { attributes } = data;
            return (
              <div key={index}>
                <RecentUpdatesCard
                  date={attributes?.updatedAt}
                  description={attributes?.content}
                  author={attributes?.author}
                  title={attributes?.title}
                />
                {index !== recentUpdateData.length - 1 && <Divider sx={{ marginBlock: '1rem' }} />}
              </div>
            );
          })}
        </CardsContainer>
      ) : (
        <Typography variant='h3' className='noDataText'>
          Currently, there are no recent updates available.
        </Typography>
      )}
    </>
  );
};

function RecentUpdatesCard({ date, description, author, imgPath, alt, title }: recentUpdateProps) {
  return (
    <CustomCard>
      <Typography className='updated-date' display={'flex'} justifyContent={'space-between'}>
        {title}
      </Typography>
      <div className='updated-description'>
        <MarkdownComponent>{description}</MarkdownComponent>
      </div>
      {imgPath && <img src={imgPath} alt={alt} />}
      <Box
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        marginTop={'1rem'}
      >
        <Typography flexShrink={0} fontSize={'.625rem'} color={'#999'}>
          {formatDate(date)}
        </Typography>
        <Typography flexShrink={0} fontSize={'.625rem'} color={'#999'}>
          Added by <span style={{ fontStyle: 'italic' }}>{author}</span>
        </Typography>
      </Box>
      {/* PureScript is a strongly-typed, purely functional programming language that compiles to JavaScript. It's designed to be a more expressive and safer alternative to JavaScript, offering advanced features and tools for functional programming. Here are some key features and aspects of PureScript. PureScript is a strongly-typed, purely functional programming language that compiles to JavaScript.  */}
    </CustomCard>
  );
}
