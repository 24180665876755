import { Box, Typography, Button, Drawer, Tooltip } from '@mui/material';
import { styled } from '@mui/system';
import React, { useState } from 'react';
import DefaultIcon from '../../../assets/images/defaultIcon.svg';
import { useNavigate } from 'react-router-dom';
import { RecentlyUpdatedCard } from '../../../Containers/RecentlyUploaded';
import Icon from '../../Icons/icons';
import { drawerProps } from './interfaces';
import Avatar from '@mui/material/Avatar';
import { KbDrawerHeader } from './navigationCard';

interface CategoryProps extends CategoryNameProps, CategoryIconProps {
  link: string;
  hasUpdate?: Boolean;
  item?: any;
  recentUpdates?: any;
  stats: any;
}
interface CategoryNameProps {
  categoryName: string;
}
interface CategoryIconProps {
  icon: string;
  alt: string;
}

const KbCategoryName = styled(Typography)(({ theme }) => ({
  maxWidth: '8.563rem',
  textAlign: 'center',
  marginTop: '1rem',
  color: theme.palette.primary.dark,
  fontWeight: 500,
}));

export const CategoryName = ({ categoryName }: CategoryNameProps) => {
  return <KbCategoryName variant='h3'> {categoryName}</KbCategoryName>;
};

const KBCategoryCard = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.contrastText,
  boxShadow: '0 0 .625rem 0 rgba(0, 0, 0, 0.15)',
  '&:hover': {
    boxShadow: '0 0 .625rem 0 rgba(0, 0, 0, 0.30)',
  },
  padding: '1rem',
  borderRadius: '.375rem',
  cursor: 'pointer',
  '& .title': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    borderBottom: '1px solid #DEDEDE',
    marginBottom: '1rem',
    color: theme.palette.text.primary,
    '& svg': {
      maxWidth: '2.125rem',
      marginBottom: '1.5rem',
    },
  },
  '& .update-section': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: theme.palette.text.primary,
    p: {
      fontSize: '2rem',
    },
    button: {
      textTransform: 'capitalize',
      borderRadius: '0.375rem',
    },
  },
}));

const CategoriesIcon = styled(Avatar)(({ theme }) => ({
  height: '2rem',
  width: '2rem',
  marginBottom: '1rem',
  '& img': {
    objectFit: 'contain',
  },
}));

export const CategoryCard = ({
  categoryName,
  link,
  icon,
  alt,
  hasUpdate,
  item,
  recentUpdates,
  stats,
}: CategoryProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setOpen(true);
  };
  const handleClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setOpen(false);
  };

  const goToValueAdds = (e: React.MouseEvent) => {
    e.stopPropagation();
    navigate(`${link}/value-adds`);
  };

  const goToBlogs = (e: React.MouseEvent) => {
    e.stopPropagation();
    navigate(`${link}/blogs`);
  };

  return (
    <Box
      onClick={() => {
        navigate(link, { state: { name: categoryName } });
      }}
    >
      <KBCategoryCard>
        <Box className='title'>
          <Typography marginBottom={0} component={'p'}>
            {categoryName}
          </Typography>
          <CategoriesIcon alt={alt} src={icon} variant='square' />
        </Box>
        <Box className='update-section'>
          <Typography fontWeight={500}>{item?.attributes?.tech_digests?.data?.length}</Typography>
          {recentUpdates?.length ? (
            <Button onClick={handleOpen} size='small' variant='contained' sx={{ borderRadius: '0.5rem' }}>
              {recentUpdates?.length} {recentUpdates?.length > 1 ? 'Updates' : 'Update'}
            </Button>
          ) : null}
        </Box>

        <Box borderTop={'1px solid #DEDEDE'} paddingTop={3} marginTop={3} display={'flex'}>
          <Tooltip title={'Value Adds'} arrow placement='top'>
            <Box paddingRight={2} display={'flex'} marginRight={2} onClick={(e: React.MouseEvent) => goToValueAdds(e)}>
              <Typography fontSize={24} marginRight={0.5}>
                {stats?.valueAddCount || 0}
              </Typography>
              <Icon kind={'valueAdd'} size={30} color={'#0b93df'} />
            </Box>
          </Tooltip>
          <Tooltip title={'Blogs'} arrow placement='top'>
            <Box paddingRight={2} display={'flex'} onClick={(e: React.MouseEvent) => goToBlogs(e)}>
              <Typography fontSize={24} marginRight={0.5}>
                {stats?.blogsCount || 0}
              </Typography>
              <Icon kind={'blog'} size={32} color='#0b93df' />
            </Box>
          </Tooltip>
        </Box>
        <RecentUpdateDrawer
          data={recentUpdates}
          openDrawer={open}
          name={categoryName}
          handleCloseDrawer={handleClose}
        />
      </KBCategoryCard>
    </Box>
  );
};

export const CategoryIcon = ({ icon, alt }: CategoryIconProps) => {
  return <>{icon ? <img src={icon} alt={alt} /> : <img src={DefaultIcon} alt='' />}</>;
};

export function RecentUpdateDrawer({ openDrawer, handleCloseDrawer, name, data, src, alt }: drawerProps) {
  const DrawerList = (
    <Box role='presentation' sx={{ paddingBottom: '5.2rem' }}>
      <KbDrawerHeader>
        <Typography component={'p'} sx={{ ml: 1 }}>
          {name}
        </Typography>
        <Box paddingInline={'.5rem'} style={{ cursor: 'pointer' }} onClick={handleCloseDrawer}>
          <Icon kind={'close'} size={12} />
        </Box>
      </KbDrawerHeader>

      <RecentlyUpdatedCard recentUpdateData={data} />
    </Box>
  );
  return (
    <div>
      <Drawer onClick={(e) => e.stopPropagation()} open={openDrawer} onClose={handleCloseDrawer} anchor={'right'}>
        {DrawerList}
      </Drawer>
    </div>
  );
}
