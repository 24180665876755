import { useState, useEffect } from 'react';
import { axiosInstance } from '../OrionAxiosInstance';

export type IApiResponse = {
  data: any;
  error: any;
  loading: boolean;
};

export const useFetchOrion = (url: string): IApiResponse => {
  const [data, setData] = useState<any>();
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const getAPIData = async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(url)
      setData(res?.data);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  };
  useEffect(() => {
    getAPIData();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { data, error, loading };
};
