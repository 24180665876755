import React from 'react';
import { Box } from '@mui/material';
import { Heading } from '../../atoms/Heading';
import { SearchBox } from '../../atoms/SearchBox';
import { styled } from '@mui/material/styles';
import { Container } from '@mui/system';

const KbFilters = styled('div')(({ theme }) => ({
  background: theme.palette.primary.contrastText,
  '& .search-box': {
    maxWidth: '48.25rem',
    margin: '0 auto',
    paddingBottom: '10.188rem',
    '& .heading': {
      marginBottom: '1.875rem',
    },
  },
  '& .dropdownWrapper': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '1.25rem',
  },
}));

export const Filters = () => {
  return (
    <KbFilters>
      <Container>
        <Box className='search-box'>
          <Heading variant='h1' align='center' title='Hello, how may I help you ?' className='heading' />
          <SearchBox id='search' labelText='Search for Tech Digest' onlySearchIcon={false} />
        </Box>
      </Container>
    </KbFilters>
  );
};
