import { Box, Grid, ListItem, Tooltip } from '@mui/material';
import React, { useContext } from 'react';
import {
  KBSubCategory,
  KBSubCategoryCard,
  KBSubCategoryName,
  KBSubCategoryTechDigest,
  KBSubCategoryTechDigestImg,
  KBSubCategoryTechDigestName,
  KBSubCategoryTotalCount,
  SubCateoryGroupCount,
  SubCateoryGroupCountItem,
} from './style';
import { Expertise } from '../../Components/atoms/Card/navigationCard';
import Blogs from '../../assets/images/blogs.svg';
import Icon from '../../Components/Icons/icons';
import { useFetchOrion } from '../../Utils/hooks/useFetchOrion';
import { UserContext, UserContextType } from '../../Context/UserContext';

interface ArticalListingContentInterface {
  data: any[];
  location?: any;
}

export const SubCategoryContent = ({ data }: ArticalListingContentInterface) => {
  const { category } = useContext<UserContextType>(UserContext);

  const { data: ValueAddData } = useFetchOrion(`tech/category/${category?.name}/value-adds`);

  const { data: BlogsData } = useFetchOrion(`tech/category/${category?.name}/blogs`);

  const { data: TeamsData } = useFetchOrion(`tech/category/${category?.name}/teams`);

  const getCountsPerSubCategory = (subCategory: string) => {
    const filterData = (data: any, key: string) => {
      return (
        data &&
        data?.technologies
          ?.filter((technology: any) => {
            if (subCategory === 'Others') {
              return !technology?.subCategory?.name && technology[key].length > 0;
            }
            return technology?.subCategory?.name === subCategory && technology[key].length > 0;
          })
          .flatMap((technology: any) => technology[key])
      );
    };

    const valueAddDataForSubCategory = filterData(ValueAddData, 'valueAdd');
    const blogsDataForSubCategory = filterData(BlogsData, 'blog');
    const teamsDataForSubCategory = filterData(TeamsData, 'teams');
    return { valueAddDataForSubCategory, blogsDataForSubCategory, teamsDataForSubCategory };
  };

  return (
    <>
      <KBSubCategory>
        <Grid container spacing={4}>
          {data.map((tech: any) => {
            const countsPerSubCategory = getCountsPerSubCategory(tech.subCategory);
            return (
              <Grid key={tech.subCategory} item xs={4} display={'flex'} flexDirection={'column'}>
                <KBSubCategoryCard>
                  <KBSubCategoryName>{tech.subCategory}</KBSubCategoryName>
                  <Box display={'flex'} alignItems={'center'} alignSelf={'flex-start'} flexWrap={'wrap'}>
                    {tech?.techDigests.map((techDigest: any) => {
                      return (
                        <KBSubCategoryTechDigest
                          key={techDigest?.id}
                          to={`/${
                            techDigest?.attributes?.tech_categories?.data[0]?.attributes?.slug
                          }/${encodeURIComponent(techDigest?.attributes?.name)}`}
                        >
                          <Box display={'flex'} marginBottom={1}>
                            {techDigest?.attributes?.icon?.data?.attributes?.url && (
                              <KBSubCategoryTechDigestImg
                                width={20}
                                src={techDigest?.attributes?.icon?.data?.attributes?.url}
                                alt={techDigest?.attributes?.icon?.data?.attributes?.name}
                              />
                            )}
                            <KBSubCategoryTechDigestName>{techDigest?.attributes?.name}</KBSubCategoryTechDigestName>
                          </Box>

                          <Expertise expertise_level={techDigest?.attributes?.expertise_level} />
                        </KBSubCategoryTechDigest>
                      );
                    })}
                  </Box>
                  <SubCateoryGroupCount>
                    {ValueAddData && (
                      <ListItem>
                        <Tooltip title={'Value Adds'} arrow placement='top'>
                          <SubCateoryGroupCountItem
                            to={`/${category?.slug}/${tech.subCategory}/value-adds`}
                            state={{
                              data: countsPerSubCategory.valueAddDataForSubCategory,
                              item: 'valueAdd',
                            }}
                            style={{ display: 'flex', textDecoration: 'none', alignItems: 'center' }}
                          >
                            <KBSubCategoryTotalCount>
                              {countsPerSubCategory.valueAddDataForSubCategory.length}
                            </KBSubCategoryTotalCount>
                            <Icon kind={'valueAdd'} size={20} color={'#0b93df'} />
                          </SubCateoryGroupCountItem>
                        </Tooltip>
                      </ListItem>
                    )}
                    {BlogsData && (
                      <ListItem>
                        <Tooltip title={'Blogs'} arrow placement='top'>
                          <SubCateoryGroupCountItem
                            to={`/${category?.slug}/${tech.subCategory}/blogs`}
                            state={{
                              data: countsPerSubCategory.blogsDataForSubCategory,
                              item: 'blogs',
                            }}
                            style={{ display: 'flex', textDecoration: 'none', alignItems: 'center' }}
                          >
                            <KBSubCategoryTotalCount>
                              {countsPerSubCategory.blogsDataForSubCategory.length}
                            </KBSubCategoryTotalCount>
                            <img src={Blogs} alt={'blogs'} width={22} />
                          </SubCateoryGroupCountItem>
                        </Tooltip>
                      </ListItem>
                    )}
                    {TeamsData && (
                      <ListItem>
                        <Tooltip title={'Teams'} arrow placement='top'>
                          <SubCateoryGroupCountItem
                            to={`/${category?.slug}/${tech.subCategory}/teams`}
                            state={{
                              data: countsPerSubCategory.teamsDataForSubCategory,
                              item: 'teams',
                            }}
                            style={{ display: 'flex', textDecoration: 'none', alignItems: 'center' }}
                          >
                            <KBSubCategoryTotalCount>
                              {countsPerSubCategory.teamsDataForSubCategory.length}
                            </KBSubCategoryTotalCount>
                            <Icon kind={'teams'} size={20} color={'#0b93df'} />
                          </SubCateoryGroupCountItem>
                        </Tooltip>
                      </ListItem>
                    )}
                  </SubCateoryGroupCount>
                </KBSubCategoryCard>
              </Grid>
            );
          })}
        </Grid>
      </KBSubCategory>
    </>
  );
};
