import { Box, Container } from '@mui/material';
import { Categories } from '../../Components/molecules/Categories';
import { Filters } from '../../Components/molecules/Filters';

const Dashboard = () => {
  return (
    <Box>
      <Filters />
      <Container>
        <Categories />
      </Container>
    </Box>
  );
};

export default Dashboard;
