export const articleTypeLabel = {
  blogTlArticle: 'BLOGS & TL ARTICLES',
  hardProblem: 'Hard Problem',
};

export const authorLabel = {
  talentica: 'Talentica',
};

export const statisticsLabel = {
  valueAdds: 'Value Adds',
  teams: 'Teams',
  publications: 'Publications',
};