import React, { createContext } from 'react';

export interface UserContextType {
  user: boolean | null;
  setUser: React.Dispatch<React.SetStateAction<boolean | null>>;
  homePageData: any;
  setHomePageData: React.Dispatch<React.SetStateAction<Object | null>>;
  category: any;
  setCategory: React.Dispatch<React.SetStateAction<Object | null>>;
}

// Create the context
export const UserContext = createContext<UserContextType>({
  user: null,
  setUser: () => {},
  homePageData: null,
  setHomePageData: () => {},
  category: null,
  setCategory: () => {},
});
