import { useParams } from 'react-router-dom';
import { useFetchOrion } from '../../Utils/hooks/useFetchOrion';
import { useEffect, useState } from 'react';
import { CategoryValueAdd } from './CategoryValueAdd';

export const CategoryValueAdds = () => {
  const { category } = useParams();
  const [valueAdds, setValueAdds] = useState<any>({});
  const { data } = useFetchOrion(`tech/category/${category}/value-adds`);
  useEffect(() => {
    if (category !== undefined) {
      if (data) {
        const result: any[] = data?.technologies?.flatMap((technology: any) => technology?.valueAdd);
        setValueAdds(result);
      }
    }
  }, [category, data]);
  return <>{valueAdds?.length && <CategoryValueAdd key={Math.random()} valueAdds={valueAdds} />}</>;
};
