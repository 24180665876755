import { AppBar, Box, Button } from '@mui/material';
import { SearchBox } from '../atoms/SearchBox';
import Breadcrumb from '../atoms/Breadcrumb';
import Icon from '../Icons/icons';
import { styled } from '@mui/material/styles';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { UserContextType, UserContext } from '../../Context/UserContext';

interface HeaderProps {
  isSearch?: boolean;
  isBreadcrumb?: boolean;
  isStatistics?: boolean;
}
const KbHeader = styled(AppBar)(({ theme }) => ({
  paddingBlock: '1.644rem',
  '& .btn-logout': {
    color: '#000',
    '&:hover': {
      '& svg': {
        fill: '#0B93DF',
      },
      color: '#0B93DF',
    },
  },
  '& .logo': {
    display: 'flex',
    alignItems: 'center',
  },
  '& .headerWrapper': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& .headerRight': {
      display: 'flex',
      gap: '1rem',
      alignItems: 'center',
    },
  },
  '& .header-search-box': {
    maxWidth: '100%',
    width: '23.25rem',
    '& .MuiButtonBase-root': {
      paddingInline: '0.938rem',
    },
    '& .MuiInputBase-root': {
      height: '2.125rem',
      '& .MuiInputBase-input': {
        height: '2.125rem',
        boxSizing: 'border-box',
      },
    },
    '& .MuiFormLabel-root': {
      fontSize: '0.75rem',
    },
  },
}));

export const Header = ({ isSearch, isBreadcrumb, isStatistics }: HeaderProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setUser, category } = useContext<UserContextType>(UserContext);

  const logOut = () => {
    setUser(false);
    localStorage.clear();
    navigate('/login');
  };

  const goToStats = () => {
    navigate(`${category?.slug}/statistics`, { state: { name: location?.state?.name } });
  };

  return (
    <KbHeader color='inherit' position='sticky'>
      <Box className='headerWrapper'>
        <Box className='logo'>
          <Link to='/'>
            <Icon kind='logo' width={66} height={42} />
          </Link>
          {isBreadcrumb && <Breadcrumb />}
        </Box>
        {location.pathname !== '/' &&
          location.pathname !== '/dashboard' &&
          location.pathname !== '/search' &&
          isSearch && (
            <SearchBox
              id='search'
              labelText='Search for Tech Digest'
              onlySearchIcon={true}
              className='header-search-box'
            />
          )}
        <Box className='headerRight'>
          {isStatistics && location.pathname !== '/' && location.pathname !== `/${category?.slug}/statistics` && (
            <Button
              sx={{ mr: '1rem' }}
              className='btn-logout'
              onClick={goToStats}
              startIcon={<Icon size={16} kind='stats' />}
            >
              Stats
            </Button>
          )}
          <Button className='btn-logout' onClick={logOut} startIcon={<Icon size={16} kind='logout' />}>
            Logout
          </Button>
        </Box>
      </Box>
    </KbHeader>
  );
};
