import ReactECharts from 'echarts-for-react';

interface IBarChartDataInterface {
  xAxisData: any[];
  barCountData: any[];
}

const BarChart = ({ xAxisData, barCountData }: IBarChartDataInterface) => {
  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    xAxis: {
      type: 'category',
      data: xAxisData,
      axisLabel: {
        rotate: 45,
        interval: 0,
      },
      axisTick: {
        alignWithLabel: true,
      },
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        data: barCountData,
        type: 'bar',
        itemStyle: {
          color: '#42A5F5',
        },
        barWidth: 15,
      },
    ],
  };

  return <ReactECharts option={option} />;
};

export default BarChart;
