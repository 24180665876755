import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { LeftSideBarHeader } from '../../Components/molecules/LeftSideBar';
import { useParams } from 'react-router-dom';
import { IApiResponse, useFetch } from '../../Utils/hooks/useFetch';
import { IAttributes } from '../../types/TechDigest';
import { ArticalDetailedContent } from './articalDetailedContent';
import { BriefDetails } from './briefDetails';
import { ArticalListingContent } from './articalListingContent';
import { TechTalks } from './techTalks';
import { Experts } from './experts';
import { Team } from './team';
import { CircularLoader } from '../../Components/atoms/CircularLoader';
import { RecentlyUpdatedCard } from '../../Containers/RecentlyUploaded';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { theme } from '../../theme';
import { OurExpertise } from './ourExpertise';
import TechSummery from '../../assets/images/techsummery.svg';
import TechSummeryred from '../../assets/images/techsummeryred.svg';
import { BlogsTlArticles } from './blogsAndTlArticles';
import Blogs from '../../assets/images/blogs.svg';
import Blogsred from '../../assets/images/blogsred.svg';
import RecentUpdates from '../../assets/images/recentupdates.svg';
import RecentUpdatesred from '../../assets/images/recentupdatesred.svg';
import Expertises from '../../assets/images/ourexpertise.svg';
import Expertisesred from '../../assets/images/ourexpertisered.svg';
import { Banner } from './banner';
import { Teams } from '../Teams';
import { useFetchOrion } from '../../Utils/hooks/useFetchOrion';
import { ValueAdd } from './valueAdd';
import Icon from '../../Components/Icons/icons';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const KbLanguageDetails = styled(Box)(({ theme }) => ({
  padding: '2.5rem',
  display: 'flex',
  flexDirection: 'row',
  height: 'calc(100vh - 6.125rem)',
  overflow: 'hidden',
  gap: '2.5rem',

  '& .listItemText': {
    textDecoration: 'none',
    color: theme.palette.text.primary,
    '&.active': {
      color: theme.palette.primary.main,
      fontWeight: 500,
    },
  },

  '& .tab-wrapper': { width: '100%', backgroundColor: '#fff', overflow: 'auto' },
  '& .tab-header': {
    padding: '1rem 2.5rem 1rem 2.5rem',
    position: 'sticky',
    top: 0,
    backgroundColor: '#fff',
    zIndex: 1,
  },
}));

export const TechDigestsDetails = () => {
  const location = useParams();
  const technology = encodeURIComponent(location?.technology!);
  const { data, loading }: IApiResponse = useFetch(`tech-digests?filters[name][$eq]=${technology}&populate=deep,3`);
  const { data: teamsData, loading: teamsLoading }: IApiResponse = useFetchOrion(
    `technology/getTechDigest/label?techDigestLabel=${technology}`
  );
  const { data: valueAddData, loading: valueAddLoading }: IApiResponse = useFetchOrion(
    `valueAdd/valueAddsByTag?tag=${technology}`
  );
  const [activeTab] = useState<string>('Brief Details');
  const [techDigestsData, setTechDigestsData] = useState<IAttributes>();
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (data) {
      setTechDigestsData(data[0]?.attributes);
    }
  }, [data]);

  function filterByContentType(contentType: string, data: any) {
    if (data && data.attributes && data.attributes.contents && data.attributes.contents.data) {
      return data.attributes.contents.data.filter(
        (item: any) =>
          item.attributes &&
          item.attributes.content_type &&
          item.attributes.content_type.data &&
          item.attributes.content_type.data.attributes &&
          item.attributes.content_type.data.attributes.slug === contentType
      );
    }
    return null;
  }
  let content;
  switch (activeTab) {
    case 'Brief Details':
      content = <BriefDetails data={techDigestsData?.description!} tabName='Brief Details' />;
      break;
    case 'Best Practices':
      content = (
        <ArticalDetailedContent data={filterByContentType('best-practices', data[0])} tabName='Best Practices' />
      );
      break;
    case 'How To Setup':
      content = <ArticalDetailedContent data={filterByContentType('how-to-set-up', data[0])} tabName='How To Setup' />;
      break;
    case 'Code Starter Kit':
      content = (
        <ArticalDetailedContent data={filterByContentType('code-starter-kit', data[0])} tabName='Code Starter Kit' />
      );
      break;
    case 'Our Thoughts':
      content = <ArticalDetailedContent data={filterByContentType('our-thoughts', data[0])} tabName='Our Thoughts' />;
      break;
    case 'Hard Problems':
      content = (
        <ArticalListingContent
          data={filterByContentType('hard-problem', data[0])}
          technology={technology}
          tabName='Hard Problems'
        />
      );
      break;
    case 'Innovations':
      content = (
        <ArticalListingContent
          data={filterByContentType('innovations', data[0])}
          technology={technology}
          tabName='Innovations'
        />
      );
      break;
    case 'Blogs & TL Articles':
      content = (
        <ArticalListingContent
          data={filterByContentType('blogs-and-tl-articles', data[0])}
          technology={technology}
          tabName='Blogs & TL Articles'
        />
      );
      break;
    case 'Common Mistakes':
      content = (
        <ArticalDetailedContent data={filterByContentType('common-mistakes', data[0])} tabName='Common Mistakes' />
      );
      break;
    case 'Tech Talks':
      content = <TechTalks data={filterByContentType('tech-talks', data[0])} tabName='Tech Talks' />;
      break;
    case 'Experts':
      content = <Experts tabName='Experts' data={data} />;
      break;
    case 'Team':
      content = <Team tabName='Team' />;
      break;
    default:
      content = '';
  }

  // tabs
  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ py: 2, px: 5 }}>{children}</Box>}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const tabIndicaterStyle = {
    height: '0.219rem',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '1rem',
  };
  return (
    <KbLanguageDetails>
      <Box className='tab-wrapper'>
        <Box className='tab-header'>
          <Tabs
            value={value}
            onChange={handleChange}
            textColor='inherit'
            aria-label='basic tabs example'
            TabIndicatorProps={{
              style: tabIndicaterStyle,
            }}
          >
            {[
              { label: 'Tech Summary', iconSrc: content ? TechSummery : TechSummeryred },
              { label: 'Our Expertise', iconSrc: techDigestsData?.our_expertise?.data ? Expertises : Expertisesred },
              {
                label: 'Blogs/TL Articles',
                iconSrc: techDigestsData?.blogs_tl_articles?.data.length ? Blogs : Blogsred,
              },
              {
                label: 'Recent Updates',
                iconSrc: techDigestsData?.recent_updates?.data?.length ? RecentUpdates : RecentUpdatesred,
                // iconSrc: RecentUpdatesred,
              },
              {
                label: 'Teams',
                icon: <Icon kind={'teams'} size={18} color={teamsData?.length ? '#0b93df' : '#ff0000'} />,
              },
              {
                label: 'Value Add',
                icon: <Icon kind={'valueAdd'} size={18} color={valueAddData?.length ? '#0b93df' : '#ff0000'} />,
              },
            ].map((item: any, i: number) => {
              return (
                <Tab
                  key={i}
                  label={item.label}
                  {...a11yProps(i)}
                  sx={{
                    fontSize: '1rem',
                  }}
                  icon={item?.iconSrc ? <img src={item.iconSrc} alt={item.label} /> : item.icon}
                  iconPosition='start'
                />
              );
            })}
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          {loading ? (
            <CircularLoader />
          ) : (
            <>
              <LeftSideBarHeader
                title={techDigestsData?.name!}
                technologyAdoption={techDigestsData?.maturity_level?.data?.attributes?.name!}
                date={techDigestsData?.updatedAt}
                src={techDigestsData?.icon?.data?.attributes?.url}
                alt={techDigestsData?.icon?.data?.attributes?.alternativeText}
              />
              <Banner date={techDigestsData?.updatedAt} />
              <>{content}</>
            </>
          )}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          {loading ? (
            <CircularLoader />
          ) : (
            <OurExpertise
              src={techDigestsData?.icon?.data?.attributes?.url}
              alt={techDigestsData?.icon?.data?.attributes?.alternativeText}
              title={techDigestsData?.name!}
              technologyAdoption={techDigestsData?.maturity_level?.data?.attributes?.name!}
              data={techDigestsData?.our_expertise!}
              tabName='Our Expertise'
            />
          )}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          {loading ? (
            <CircularLoader />
          ) : (
            <>
              <BlogsTlArticles data={techDigestsData?.blogs_tl_articles?.data} />
              {/* <BlogsTlArticles data={[]} /> */}
            </>
          )}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          {loading ? (
            <CircularLoader />
          ) : (
            <RecentlyUpdatedCard recentUpdateData={techDigestsData?.recent_updates?.data} />
          )}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          {teamsLoading ? <CircularLoader /> : <Teams data={teamsData} />}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={5}>
          {valueAddLoading ? <CircularLoader /> : <ValueAdd data={valueAddData} />}
        </CustomTabPanel>
      </Box>
    </KbLanguageDetails>
  );
};
